var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t(_vm.curPageData.title))+" ("+_vm._s(_vm.curPageData.id)+") ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{staticClass:"pb-0"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.adjust.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('to_bankcode'),"type":"text","filled":"","readonly":""},model:{value:(_vm.curPageData.bank_code),callback:function ($$v) {_vm.$set(_vm.curPageData, "bank_code", $$v)},expression:"curPageData.bank_code"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('to_cardnumber'),"type":"text","filled":"","readonly":""},model:{value:(_vm.curPageData.card_number),callback:function ($$v) {_vm.$set(_vm.curPageData, "card_number", $$v)},expression:"curPageData.card_number"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('currency'),"type":"text","filled":"","readonly":""},model:{value:(_vm.curPageData.currency_code),callback:function ($$v) {_vm.$set(_vm.curPageData, "currency_code", $$v)},expression:"curPageData.currency_code"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('amount'),"type":"text","filled":"","readonly":""},model:{value:(_vm.curPageData.amount),callback:function ($$v) {_vm.$set(_vm.curPageData, "amount", $$v)},expression:"curPageData.amount"}})],1)],1),_c('v-divider',{staticClass:"mb-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"transaction_number","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('transaction_number'),"name":"transaction_number","type":"text"},model:{value:(_vm.editedForm.transaction_number),callback:function ($$v) {_vm.$set(_vm.editedForm, "transaction_number", _vm._n($$v))},expression:"editedForm.transaction_number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"client_order_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('client_order_id'),"name":"client_order_id","type":"text"},model:{value:(_vm.editedForm.client_order_id),callback:function ($$v) {_vm.$set(_vm.editedForm, "client_order_id", $$v)},expression:"editedForm.client_order_id"}})]}}],null,true)})],1)],1)],1)]}}])})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.manualMatch}},[_vm._v(_vm._s(_vm.$t('confirm')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }