import Home from '../views/Home.vue'
import GuestMiddleware from '../middleware/guest'
import CheckAuth from '../middleware/check-auth'
import CheckRole from '../middleware/check-role'
import * as system_user from '../constants/system_user'

const routes = [
  {
    path: '/sign-in',
    name: 'signin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Signin.vue'),
    beforeEnter: (to, from, next) => {
      GuestMiddleware(to, from, next)
    },
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../layouts/AuthenticatedLayout.vue'),
    beforeEnter: (to, from, next) => {
      CheckAuth(to, from, next)
    },
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        beforeEnter: (to, from, next) => {
          CheckAuth(to, from, next)
        }
      },
      {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      { path: '/service-provider',
        name: 'serviceProvider',
        component: () => import(/* webpackChunkName: "about" */ '../views/ServiceProvider/index.vue'),
        beforeEnter: (to, from, next) => {
          CheckRole(to, from, next, [system_user.UserType.ROOT, system_user.UserType.MANAGER])
        }
      },
      { path: '/bank',
        name: 'bank',
        component: () => import(/* webpackChunkName: "about" */ '../views/Bank/index.vue'),
        beforeEnter: (to, from, next) => {
          CheckRole(to, from, next, [system_user.UserType.ROOT, system_user.UserType.MANAGER])
        }
      },
      { path: '/bank-card',
        component: () => import(/* webpackChunkName: "about" */ '../views/BankCard/index.vue'),
        children: [
          { path: '', redirect: { name: 'bankCard.list' } },
          {
            path: 'list',
            name: 'bankCard.list',
            component: () => import(/* webpackChunkName: "about" */ '../views/BankCard/List.vue')
          },
          {
            path: 'report',
            name: 'bankCard.report',
            component: () => import(/* webpackChunkName: "about" */ '../views/BankCard/Report.vue')
          },
        ],
        beforeEnter: (to, from, next) => {
          CheckRole(to, from, next, [system_user.UserType.ROOT, system_user.UserType.MANAGER])
        }
      },
      { path: '/contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/Settings/index.vue'),
        children: [
          { path: '', redirect: { name: 'settings.password' } },
          {
            path: '/settings/password',
            name: 'settings.password',
            component: () => import(/* webpackChunkName: "about" */ '../views/Settings/Password.vue'),
          },
          {
            path: '/settings/withdrawal-key',
            name: 'settings.withdrawalKey',
            component: () => import(/* webpackChunkName: "about" */ '../views/Settings/WithdrawalKey.vue'),
          }
        ],
        beforeEnter: (to, from, next) => {
          CheckAuth(to, from, next)
        }
      },
      { path: '/contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/Contact/index.vue'),
        children: [
          { path: '', redirect: { name: 'contact.client' } },
          {
            path: 'client',
            name: 'contact.client',
            component: () => import(/* webpackChunkName: "about" */ '../views/Contact/Client.vue'),
            props: route => ({ query: route.query.q })
          },
          {
            path: 'staff',
            name: 'contact.staff',
            component: () => import(/* webpackChunkName: "about" */ '../views/Contact/Staff.vue'),
            props: route => ({ query: route.query.q })
          },
        ],
        beforeEnter: (to, from, next) => {
          CheckRole(to, from, next, [system_user.UserType.ROOT, system_user.UserType.MANAGER])
        }
      },
      { path: '/task/:type/:status',
        name: 'task',
        component: () => import(/* webpackChunkName: "about" */ '../views/Task/index.vue'),
        beforeEnter: (to, from, next) => {
          CheckRole(to, from, next, [
            system_user.UserType.ROOT, system_user.UserType.MANAGER, system_user.UserType.CS_AGENT,
            system_user.UserType.REPORTER, system_user.UserType.CS_AGENT_BOT
          ])
        }
      },
      { 
        path: '/auto-callback/:type',
        name: 'autoCallback',
        component: () => import(/* webpackChunkName: "about" */ '../views/AutoCallback/index.vue'),
        beforeEnter: (to, from, next) => {
          CheckRole(to, from, next, [
            system_user.UserType.ROOT, system_user.UserType.MANAGER, 
            system_user.UserType.CS_AGENT,
          ])
        }
      },
      { path: '/balance-history/:user?',
        name: 'balanceHistory',
        component: () => import(/* webpackChunkName: "about" */ '../views/BalanceHistory/index.vue'),
        beforeEnter: (to, from, next) => { //only root & manager
          CheckRole(to, from, next, [system_user.UserType.ROOT, system_user.UserType.MANAGER])
        }
      },
      { path: '/system-error-log',
        name: 'systemErrorLog',
        component: () => import(/* webpackChunkName: "about" */ '../views/SystemErrorLog/SystemErrorLog.vue'),
        beforeEnter: (to, from, next) => { 
          // Only root or manager for now, future maybe cs agent

          CheckRole(
            to, from, next, 
            [ system_user.UserType.ROOT, system_user.UserType.MANAGER ]
          )
        }
      },
      { path: '/playground-signature',
        name: 'playgroundSignature',
        component: () => import(/* webpackChunkName: "about" */ '../views/Playground/PlaygroundSignature.vue'),
        beforeEnter: (to, from, next) => { //only root 
          CheckRole(to, from, next, [system_user.UserType.ROOT])
        }
      },
      { path: '/client',
        component: () => import(/* webpackChunkName: "about" */ '../views/Client/index.vue'),
        children: [
          { path: '', redirect: { name: 'client.companySettlement' } },
          {
            path: 'withdrawal',
            name: 'client.withdrawal',
            component: () => import(/* webpackChunkName: "about" */ '../views/Client/Withdrawal/index.vue')
          },
          {
            path: 'company-top-up',
            name: 'client.companyTopup',
            component: () => import(/* webpackChunkName: "about" */ '../views/Client/CompanyTopup/index.vue')
          },
          {
            path: 'top-up',
            name: 'client.topup',
            component: () => import(/* webpackChunkName: "about" */ '../views/Client/TopupNonRedirect/index.vue')
          },
          // {
          //   path: 'company-settlement',
          //   name: 'client.companySettlement',
          //   component: () => import(/* webpackChunkName: "about" */ '../views/Client/CompanySettlement/index.vue')
          // },
          // {
          //   path: 'customer-payment',
          //   name: 'client.customerPayment',
          //   component: () => import(/* webpackChunkName: "about" */ '../views/Client/CustomerPayment/index.vue')
          // },
          {
            path: 'customer-transaction/:type/:status',
            name: 'client.customerTransaction',
            component: () => import(/* webpackChunkName: "about" */ '../views/Client/Transaction/index.vue'),
          },
          {
            path: 'finance-stats',
            name: 'client.financeStats',
            component: () => import(/* webpackChunkName: "about" */ '../views/Client/FinanceStats/index.vue')
          },
          { path: 'balance-history',
            name: 'client.balanceHistory',
            component: () => import(/* webpackChunkName: "about" */ '../views/Client/BalanceHistory/index.vue'),
          },
          { path: 'blacklist-ips',
            name: 'client.blacklistIps',
            component: () => import(/* webpackChunkName: "about" */ '../views/Client/BlacklistIps/index.vue'),
          },
        ],
        beforeEnter: (to, from, next) => {
          CheckRole(to, from, next, [system_user.UserType.CLIENT])
        }
      },
      { path: '/client-csa',
        component: () => import(/* webpackChunkName: "about" */ '../views/Client/index.vue'),
        children: [
          {
            path: 'customer-transaction/:type/:status',
            name: 'clientCsa.customerTransaction',
            component: () => import(/* webpackChunkName: "about" */ '../views/Client/Transaction/index.vue'),
          },
          { path: 'balance-history',
            name: 'clientCsa.balanceHistory',
            component: () => import(/* webpackChunkName: "about" */ '../views/Client/BalanceHistory/index.vue'),
          },
        ],
        beforeEnter: (to, from, next) => {
          CheckRole(to, from, next, [system_user.UserType.CLIENT_CSA])
        }
      },
    ],
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "about" */ '../views/Error/NotFound.vue'),
  }
];

export default routes
