<template>
  <div>
    <v-card-title>
      {{$t('all')}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="userList"
      :search="search"
      :loading="busyInit"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{$t('client')}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="addClient">{{$t('new_client')}}</v-btn>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
<!--              <v-btn color="primary" dark class="mb-2" v-on="on">{{$t('new_client')}}</v-btn>-->
            </template>
            <client-form
              :form-title="formTitle"
              :edited-item="editedItem" :edited-index="editedIndex"
              @fetch="fetch" @close="close"
            ></client-form>
          </v-dialog>
          <v-dialog v-model="bankCardDialog">
            <client-bank-card
              :bank-card-list="bankCardList"
              :edited-item="editedItem"
              :bank-cards-loading="bankCardsLoading"
              @fetch="fetch" @close="close" @fetchBankCards="fetchBankCards"
            ></client-bank-card>
          </v-dialog>
          <v-dialog v-model="trxCfgDialog" max-width="1200px">
            <client-trx-cfg-form
              :default-item="defaultItem"
              :edited-item="editedItem" @fetch="fetch" @close="close"
              :dialog-visible="trxCfgDialog"
            ></client-trx-cfg-form>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.client_id="{ item }">
        <router-link v-if="item.client_id" :to="{name: 'contact.client', query: {q: item.client_id.toString()}}">{{item.client_id}}</router-link>
      </template>
      <template v-slot:item.user_type="{ item }">
        {{ getKeyByValue(UserType, item.user_type) }}
      </template>
      <template v-slot:item.balance="{ item }">
        <template v-if="item.balance">
          <div
            v-for="(balance, key) in item.balance.filter(x => x.amount > 0)"
            :key="key"
            :style="{ whiteSpace: 'nowrap' }"
          >
            <span
              :style="{ fontWeight: 'bold' }" 
            >
              {{ balance.currency }}
            </span>
            &nbsp;
            {{ balance.amount.toLocaleString(undefined, {minimumFractionDigits: 2}) }}
          </div>
          <span
            v-if="item.balance.filter(x => x.amount > 0).length === 0"
          >
            0
          </span>
        </template>
        <span v-else>-</span>
      </template>
      <template v-slot:item.user_status="{ item }">
        {{ getKeyByValue(UserStatus, item.user_status) }}
      </template>
      <template v-slot:item.assign_bankcard="{ item }">
        <v-tooltip v-if="item.assign_bankcard" bottom>
          <template v-slot:activator="{ on, attrs }">
            <a
              href="javascript:void(0);"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.assign_bankcard.length }}
            </a>
          </template>
          <div v-if="item.assign_bankcard.length === 0">
            {{$t('no_assigned_bank_cards')}}
          </div>
          <div v-else>
            <div :key="id" v-for="id in item.assign_bankcard">
              {{ id }}
            </div>
          </div>
        </v-tooltip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item, 'dialog')"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{$t('edit')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item, 'trxCfgDialog')"
              :disabled="item.user_type === UserType.CLIENT_CSA"
              v-bind="attrs"
              v-on="on"
            >
              mdi-abacus
            </v-icon>
          </template>
          <span>{{$t('client_configure_trx_cfg')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item, 'bankCardDialog')"
              :disabled="item.user_type === UserType.CLIENT_CSA"
              v-bind="attrs"
              v-on="on"
            >
              mdi-credit-card-multiple
            </v-icon>
          </template>
          <span>{{$t('bank_card_list')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="addCs(item)"
              :disabled="item.user_type === UserType.CLIENT_CSA"
              v-bind="attrs"
              v-on="on"
            >
              mdi-account-multiple-plus
            </v-icon>
          </template>
          <span>{{$t('add_client_sub_account')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click="resetPassword(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-lock-reset
              </v-icon>
              </template>
          <span>{{$t('Reset Password')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                @click="resetWithdrawalKey(item)"
                :disabled="item.user_type === UserType.CLIENT_CSA"
                v-bind="attrs"
                v-on="on"
              >
                mdi-key-change
              </v-icon>
              </template>
          <span>{{$t('Reset Withdraw Key')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              @click="deleteItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{$t('delete')}}</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="fetch">{{$t('refresh')}}</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import axios from 'axios'
  import contactMixins from '../../mixins/contactMixins'
  import * as User from '../../constants/system_user'
  import DefClient from '../../constants/DefClient';
  import ClientForm from "./ClientForm";
  import ClientBankCard from "./ClientBankCard";
  import ClientTrxCfgForm from './ClientTrxCfgForm';

  export default {
    name: 'ClientContact',
    components: {ClientBankCard, ClientForm, ClientTrxCfgForm},
    mixins: [contactMixins],
    props: {
      query: {
        type: String,
        default: ''
      },
    },
    mounted() {
      this.fetch();
      this.fetchCommissions();
    },
    computed: {
      headers () {
        return [
          {
            text: this.$t('user_id'),
            align: 'left',
            sortable: true,
            value: 'user_id',
          },
          { text: this.$t('display_name'), value: 'display_name' },
          { text: this.$t('client_name'), value: 'client_name' },
          { text: this.$t('client_id'), value: 'client_id' },
          { text: this.$t('username'), value: 'username' },
          { text: this.$t('user_type'), value: 'user_type' },
          { text: this.$t('credit_balance'), value: 'balance' },
          { text: this.$t('user_status'), value: 'user_status' },
          { text: this.$t('assigned_bank_cards'), value: 'assign_bankcard' },
          { text: this.$t('actions'), value: 'action', sortable: false },
        ]
      },
      formTitle () {
        let title =  '';
        if (this.editedIndex === -1) {
          if (this.editedItem.user_type === this.UserType.CLIENT_CSA) {
            title = this.$t('add_client_sub_account');
          } else {
            title = this.$t('new_client');
          }
        } else {
          if (this.editedItem.user_type === this.UserType.CLIENT_CSA) {
            title = this.$t('edit_client_sub_account');
          } else {
            title = this.$t('edit_client');
          }
        }
        return title;
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      search(newVal) {
        this.$router.push({ query: { ...this.$route.query, q: newVal } });
      },
      '$route.query.q': function(val) {
        this.search = val;
      }
    },
    data () {
      return {
        busyInit: false,
        busy: false,
        bankCardsLoading: false,
        dialog: false,
        bankCardDialog: false,
        trxCfgDialog: false,
        search: this.query,
        userList: [],
        commissionList: [],
        bankCardList: [],
        editedIndex: -1,
        editedItem: {
          user_id: null,
          username: '',
          password: '',
          display_name: '',
          // status: true,
          phone_code: '60',
          phone_number: '',
          client_name: '',
          address: '',
          email: '',
          user_type: User.UserType.CLIENT,
          //client csa
          client_id: '',
          //client
          trx_cfg: [],
          assign_bankcard: [],
          api_version: DefClient.API_VERSION_LATEST,
          cust_blacklist_ip: [],
          cust_blacklist_client_user: [],
          ena_login_whitelist_ip: false,
          login_whitelist_ip: [],
          api_whitelist_ip: [],
          ena_collect_trx_amt_adj: false,
          ena_org_amt_comm_calc: false,
          ena_collect_tobankcode: true,
          ena_ignore_tp_reject_callback: true,
        },
        defaultItem: {
          user_id: null,
          username: '',
          password: '',
          display_name: '',
          // status: true,
          phone_code: '60',
          phone_number: '',
          client_name: '',
          address: '',
          email: '',
          user_type: User.UserType.CLIENT,
          //client csa
          client_id: '',
          //client
          trx_cfg: [],
          assign_bankcard: [],
          // new
          api_version: DefClient.API_VERSION_LATEST,
          cust_blacklist_ip: [],
          cust_blacklist_client_user: [],
          ena_login_whitelist_ip: false,
          login_whitelist_ip: [],
          api_whitelist_ip: [],
          ena_collect_trx_amt_adj: false,
          ena_org_amt_comm_calc: false,
          ena_collect_tobankcode: true,
          ena_ignore_tp_reject_callback: true,
        },
      }
    },
    methods: {
      async fetchCommissions () {
        this.busy = true;
        let _this = this;
        axios.get('/app/api/gateway/current')
          .then(function (response) {
            _this.commissionList = response.data.data.commission 
              .map(
                x => ({
                  comm_type: x.method,
                  trx_cfg_cyc: [
                    {
                      is_default: true,
                      currency_code: null,
                      min_amt: 0,
                      max_amt: 0,
                      base_rate: x.base_rate,
                      rate: x.rate,
                      min_base_comm_amt: 0,
                      min_comm_amt: 0,
                      cvr_mode: 0,
                      cvr_mode_max_amt: null,
                    }
                  ]
                })
              );

            // Must use push to add item into the array, and cannot override
            // using trx_cfg = xxx otherwise the value will not be registered
            
            // ClientTrxCfgForm depend on the default item default trx_cfg
            // to fill in missing default client trx cfg if needed

            _this.commissionList.forEach(
              x => {
                _this.editedItem.trx_cfg.push(x);
                _this.defaultItem.trx_cfg.push(x);
              }
            )
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      async fetchBankCards () {
        this.busy = true;
        this.bankCardsLoading = true;
        let _this = this;
        axios.get('/app/api/bankcard')
          .then(function (response) {
            _this.bankCardList = response.data.data;
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.bankCardsLoading = false;
            _this.busy = false;
          })
      },
      async fetch () {
        this.busyInit = true;
        this.busy = true;
        const _this = this;
        await axios.get('/app/api/client')
          .then(function (response) {
            _this.userList = response.data.data;
            // _this.userList.sort(function(a, b) {
            //   // Compare the 2 key
            //   if (a.client_id < b.user_id) return -1;
            //   if (a.client_id > b.user_id) return 1;
            //   return 0;
            // });
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busyInit = false;
            _this.busy = false;
          })
      },
      addCs (item) {
        this.editedItem.client_id = item.user_id;
        this.editedItem.user_type = this.UserType.CLIENT_CSA;
        this.dialog = true
      },
      addClient () {
        this.dialog=true;
      },
      editItem (item, dialogType) {
        this.editedIndex = this.userList.indexOf(item);
        this.editedItem = Object.assign({}, item);

        if (item.trx_cfg) {
          this.editedItem.trx_cfg = JSON.parse(JSON.stringify(item.trx_cfg)); //deep clone
        }

        if (item.phone) {
          this.editedItem.phone_code = item.phone.code;
          this.editedItem.phone_number = item.phone.number;
        }

        if (dialogType === 'bankCardDialog') {
          this.fetchBankCards();
        }
        this[dialogType] = true
      },
      deleteItem (item) {
        if (confirm(this.$t('delete_confirmation'))) {
          this.busy = true;
          const _this = this;
          axios.delete(`/app/api/user/${item.user_id}`)
            .then(function (response) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: response.data.message,
                color: 'success',
              })
              _this.fetch();
            })
            .catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            })
            .finally(function () {
              _this.busy = false;
            })
        }
      },
      resetPassword (item) {
        if (confirm(this.$t('reset_password_confirmation'))) {
          this.busy = true;
          const _this = this;
          axios.post(`/app/api/client/${item.user_id}/resetpassword`)
            .then(function (response) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: response.data.message,
                color: 'success',
              });
              alert(_this.$t('new_password') + ': ' + response.data.data.password);
              _this.fetch();
            })
            .catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            })
            .finally(function () {
              _this.busy = false;
            })
        }
      },
      resetWithdrawalKey (item) {
        if (confirm(this.$t('reset_withdraw_key_confirmation'))) {
          this.busy = true;
          const _this = this;
          axios.post(`/app/api/client/${item.user_id}/resetwithdrawkey`)
            .then(function (response) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: response.data.message,
                color: 'success',
              })
              alert(_this.$t('new_withdraw_key') + ': ' + response.data.data.withdraw_key);
              _this.fetch();
            })
            .catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            })
            .finally(function () {
              _this.busy = false;
            })
        }
      },
      close () {
        this.dialog = false;
        this.bankCardDialog = false;
        this.trxCfgDialog = false;

        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.trx_cfg = JSON.parse(JSON.stringify(this.defaultItem.trx_cfg)); //deep clone
          this.editedIndex = -1;
        }, 200)
      },
    },
  }
</script>
